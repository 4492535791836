import React, { useState, useEffect } from 'react';
import axios from 'axios';
import withRoleAccess from '../hoc/withRoleAccess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserGraduate, faChalkboardTeacher, faBookOpen } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import './Dashboard.css';

function AdminDashboard() {
  const { t, i18n } = useTranslation();
  const [stats, setStats] = useState([]);
  const [todayClasses, setTodayClasses] = useState([]);
  const [classGroups, setClassGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const API_URL = process.env.REACT_APP_API_URL;

  const formatMinutesToHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}:${remainingMinutes.toString().padStart(2, '0')} ${t('hr')}`;
  };

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };

        const [studentsResponse, teachersResponse, classesResponse] = await Promise.all([
          axios.get(`${API_URL}/students/count`, { headers }),
          axios.get(`${API_URL}/teachers/count`, { headers }),
          axios.get(`${API_URL}/class-groups/count`, { headers })
        ]);

        setStats({
          totalStudents: studentsResponse.data.total_students,
          totalTeachers: teachersResponse.data.total_teachers,
          totalClasses: classesResponse.data.total_classes,
          totalSales: 140000, // Replace with real sales data if available
        });
      } catch (error) {
        console.error('Error fetching stats:', error);
        setError(t('fetchStatsError'));
      }
    };

    const fetchUpcomingClasses = async () => {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
        const response = await axios.get(`${API_URL}/classes`, { headers });

        const now = new Date();

        const upcomingClasses = response.data.filter(cls => {
          const classDate = new Date(cls.date);
          const [hours, minutes] = cls.schedule_time.split(':');
          classDate.setHours(hours);
          classDate.setMinutes(minutes);
          return classDate >= now;
        });

        const sortedClasses = upcomingClasses.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          const [hoursA, minutesA] = a.schedule_time.split(':');
          const [hoursB, minutesB] = b.schedule_time.split(':');
          dateA.setHours(hoursA);
          dateA.setMinutes(minutesA);
          dateB.setHours(hoursB);
          dateB.setMinutes(minutesB);
          return dateA - dateB;
        });

        const topUpcomingClasses = sortedClasses.slice(0, 10);

        const formattedClasses = topUpcomingClasses.map(cls => ({
          classid: cls.classid,
          class_code: cls.class_code,
          subject_name: cls.subject_name,
          teacher_name: cls.teacher_name,
          schedule_time: cls.schedule_time,
          schedule_hour: cls.schedule_hour, 
          date: cls.date
        }));

        setTodayClasses(formattedClasses);
      } catch (error) {
        console.error('Error fetching upcoming classes:', error);
        setError(t('fetchUpcomingClassesError'));
      }
    };

    const fetchNewCourses = async () => {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
        const [classGroupsResponse, classesResponse] = await Promise.all([
          axios.get(`${API_URL}/class-groups`, { headers }),
          axios.get(`${API_URL}/classes`, { headers })
        ]);

        const classGroups = classGroupsResponse.data;
        const classes = classesResponse.data;

        // Aggregate subjects and teachers for each class group
        const aggregatedClassGroups = classGroups.map(group => {
          const groupClasses = classes.filter(c => c.groupid === group.groupid);

          // Calculate start and end dates
          const start_date = groupClasses.length > 0 ? groupClasses[0].date : null;
          const end_date = groupClasses.length > 0 ? groupClasses[groupClasses.length - 1].date : null;

          // Aggregate subject names and teacher names
          const subjectNames = [...new Set(groupClasses.map(c => c.subject_name))].join(', ');
          const teacherNames = [...new Set(groupClasses.map(c => c.teacher_name))].join(', ');

          return { ...group, start_date, end_date, subject_name: subjectNames, teacher_name: teacherNames };
        });

        // Filter out class groups that haven't started yet
        const now = new Date();
        const newCourses = aggregatedClassGroups.filter(group => new Date(group.start_date) > now);

        setClassGroups(newCourses);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching class groups:', error);
        setError(t('fetchClassGroupsError'));
        setLoading(false);
      }
    };

    fetchStats();
    fetchUpcomingClasses();
    fetchNewCourses();
  }, [API_URL, t]);

  if (error) return <p>{error}</p>;

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: 'numeric'};
    return date.toLocaleDateString(i18n.language, options);
  };

  const formatScheduleHour = (scheduleHourString) => {
    const [hours, minutes] = scheduleHourString.split(' ').map((value) => parseInt(value));
    return `${hours}:${minutes.toString().padStart(2, '0')}`;
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="admin-dashboard-container">
      <div className="stats-container">
        <div className="stat-box">
          <div className="icon-and-text">
            <div className="icon-circle">
              <FontAwesomeIcon icon={faUserGraduate} className="fa-icon" />
            </div>
            <div className="text-content">
              <h3>{t('totalStudents')}</h3>
              <p>{stats.totalStudents}</p>
              <div className="percentage-change">
                <span className="positive-change">+10%</span> {t('thanLastMonth')}
              </div>
            </div>
          </div>
        </div>
        <div className="stat-box">
          <div className="icon-and-text">
            <div className="icon-circle">
              <FontAwesomeIcon icon={faChalkboardTeacher} className="fa-icon" />
            </div>
            <div className="text-content">
              <h3>{t('totalTeachers')}</h3>
              <p>{stats.totalTeachers}</p>
              <div className="percentage-change">
                <span className="negative-change">-0.5%</span> {t('thanLastMonth')}
              </div>
            </div>
          </div>
        </div>
        <div className="stat-box">
          <div className="icon-and-text">
            <div className="icon-circle">
              <FontAwesomeIcon icon={faBookOpen} className="fa-icon" />
            </div>
            <div className="text-content">
              <h3>{t('activeClasses')}</h3>
              <p>{stats.totalClasses}</p>
              <div className="percentage-change">
                <span className="negative-change">-0.5%</span> {t('thanLastMonth')}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="classes-calendar-container">
        <div className="list-box">
          <h2>{t('upcomingClasses')}</h2>
          {todayClasses.length === 0 ? (
            <p>{t('noUpcomingClasses')}</p>
          ) : (
            <div className="classes-cards">
              {todayClasses.map((classItem) => (
                <div key={classItem.classid} className="admin-class-card">
                  <div className="admin-class-card-header">
                    <span className="class-date">{formatDate(classItem.date)}</span>
                    <span className="class-time">{formatTime(classItem.schedule_time)}</span>
                  </div>
                  <div className="admin-class-card-body">
                    <div className="admin-class-info">
                      <strong>{t('class')}:</strong> {classItem.class_code}
                    </div>
                    <div className="admin-class-info">
                      <strong>{t('subject')}:</strong> {classItem.subject_name}
                    </div>
                    <div className="admin-class-info">
                      <strong>{t('teacher')}:</strong> {classItem.teacher_name}
                    </div>
                    <div className="admin-class-info">
                      <strong>{t('hours')}:</strong> {formatScheduleHour(classItem.schedule_hour)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="list-box">
          <h2>{t('newCourses')}</h2>
          <div className="classes-cards">
          {classGroups.map((cls, index) => (
            <div key={index} className="admin-class-card">
              <div className="admin-class-card-header">
              <span className="class-code">{cls.class_code}</span>
              <span className="course-date">{`${formatDate(cls.start_date)} - ${formatDate(cls.end_date)}`}</span>
              </div>

              <div className="admin-class-card-body">
              <div className="admin-class-info">
                      <strong>{t('subjects')}:</strong> {cls.subject_name}
                    </div>
                    <div className="admin-class-info">
                      <strong>{t('teachers')}:</strong> {cls.teacher_name}
                    </div>
                    <div className="admin-class-info">
                      <strong>{t('totalHours')}:</strong> {formatMinutesToHours(cls.total_hours)}
                    </div>
                    <div className="admin-class-info">
                      <strong>{t('registerCapacity')}:</strong> {cls.register_capacity}
                    </div>
                    <div className="admin-class-info">
                <strong>{t('type')} : {cls.isprivate ? t('private') : t('group')}</strong>
              </div>
              </div>
            </div>
          ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRoleAccess(AdminDashboard, ['admin', 'superadmin']);
