import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './CreateUserForm.css'; // Assuming you have CSS for modal styling

function SuccessModal({ details, onClose, onConfirm }) {
  return (
    <div className="panel-overlay">
          <div className="right-panel-popup">
        <h2>Confirm User Details</h2>
        <div className="modal-close-icon" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <div className="user-details-summary">
          <p><strong>First Name:</strong> {details.firstname}</p>
          <p><strong>Last Name:</strong> {details.lastname}</p>
          <p><strong>Email:</strong> {details.email}</p>
          <p><strong>Role:</strong> {details.role}</p>
          <p><strong>Phone:</strong> {details.phone}</p>
          <p><strong>Address:</strong> {details.address}</p>
          <p><strong>Nickname:</strong> {details.nickname}</p>
          <p><strong>National ID:</strong> {details.nationalid}</p>
          <p><strong>Date of Birth:</strong> {details.date_of_birth}</p>
          <p><strong>Temporary Password:</strong> {details.password}</p>
          
          {details.role === 'teacher' && (
            <>
              <p><strong>Bachelor's Degree:</strong> {details.bachelorDegree}</p>
              <p><strong>Master's Degree:</strong> {details.masterDegree}</p>
              <p><strong>Doctoral Degree:</strong> {details.doctoralDegree}</p>
              <p><strong>Hourly Rate:</strong> {details.hoursRate}</p>
              <p><strong>Part-Time:</strong> {details.isPartTime ? 'Yes' : 'No'}</p>
              <p><strong>Years of Experience:</strong> {details.yearsExperience}</p>
              
            </>
          )}
          {details.role === 'student' && (
            <>
              <p><strong>Current Education Level:</strong> {details.currentEducationLevel}</p>
              <p><strong>Guardian Name:</strong> {details.guardianName}</p>
              <p><strong>Guardian Phone:</strong> {details.guardianContact}</p>
            </>
          )}
        </div>
        <button onClick={onConfirm}>Confirm</button>
      </div>
    </div>
  );
}

export default SuccessModal;
