import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const CalendarTaskList = ({ tasks }) => {
  const { i18n, t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const tasksPerPage = 20;

  const now = new Date();
  const todayStart = new Date(now.setHours(0, 0, 0, 0));
  const todayEnd = new Date(now.setHours(23, 59, 59, 999));
  const fiveDaysFromNow = new Date(now.getTime() + 5 * 24 * 60 * 60 * 1000);

  const formatDate = (date) => {
    const locale = i18n.language; // Get the current language from i18n
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Date(date).toLocaleDateString(locale, options);
  };

  const formatTime = (date) => {
    const locale = i18n.language;
    const options = { hour: '2-digit', minute: '2-digit', hour12: false };
    return new Date(date).toLocaleTimeString(locale, options);
  };

  const calculateDuration = (start, end) => {
    if (!end) return 'N/A';
    const startTime = new Date(start);
    const endTime = new Date(end);
    const durationInMinutes = (endTime - startTime) / (1000 * 60);
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = Math.round(durationInMinutes % 60);
    return `${hours}:${minutes.toString().padStart(2, '0')}`; // Format as hh:mm
  };

  const sortedTasks = [...tasks].sort((a, b) => new Date(a.start) - new Date(b.start));
  const filteredTasks = sortedTasks.filter(task =>
    new Date(task.start) >= new Date() &&
    task.title &&
    task.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const indexOfLastTask = currentPage * tasksPerPage;
  const indexOfFirstTask = indexOfLastTask - tasksPerPage;
  const currentTasks = filteredTasks.slice(indexOfFirstTask, indexOfLastTask);
  const totalPages = Math.ceil(filteredTasks.length / tasksPerPage);

  const goToPage = (pageNumber) => setCurrentPage(pageNumber);
  const goToPreviousPage = () => setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  const goToNextPage = () => setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));

  const getStatusClass = (startDate) => {
    const taskDate = new Date(startDate);
    if (taskDate >= todayStart && taskDate <= todayEnd) {
      return 'today';
    } else if (taskDate < fiveDaysFromNow) {
      return 'upcoming';
    } else {
      return 'on-schedule';
    }
  };

  const getStatusText = (startDate) => {
    const taskDate = new Date(startDate);
    if (taskDate >= todayStart && taskDate <= todayEnd) {
      return t('statusToday');
    } else if (taskDate < fiveDaysFromNow) {
      return t('statusUpcoming');
    } else {
      return t('statusScheduled');
    }
  };

  return (
    <div className="task-list-container">
      <div className="search-container">
        <input 
          type="text" 
          placeholder={t('searchPlaceholder')} 
          value={searchQuery} 
          onChange={(e) => setSearchQuery(e.target.value)} 
          className="search-box"
        />
      </div>
      <div className="pagination-container">
        <button 
          onClick={goToPreviousPage} 
          disabled={currentPage === 1} 
          className="page-button"
        >
          {t('previousButton')}
        </button>
        {[...Array(totalPages).keys()].map(number => (
          <button
            key={number + 1}
            onClick={() => goToPage(number + 1)}
            className={`page-button ${currentPage === number + 1 ? 'active' : ''}`}
          >
            {number + 1}
          </button>
        ))}
        <button 
          onClick={goToNextPage} 
          disabled={currentPage === totalPages} 
          className="page-button"
        >
          {t('nextButton')}
        </button>
      </div>
      <table className="task-list-table">
        <thead>
          <tr>
            <th>{t('dateColumn')}</th>
            <th>{t('timeColumn')}</th>
            <th>{t('hoursColumn')}</th>
            <th className="bold-column">{t('titleColumn')}</th>         
            <th>{t('videoRoomColumn')}</th>
            <th>{t('statusColumn')}</th>
          </tr>
        </thead>
        <tbody>
          {currentTasks.map((task) => (
            <tr key={task.id}>
              <td>{formatDate(task.start)}</td>
              <td>{`${formatTime(task.start)}-${task.end ? formatTime(task.end) : 'N/A'}`}</td>
              <td>{calculateDuration(task.start, task.end)}</td>
              <td>{task.title}</td>
              <td><a href={task.video_call_link} target="_blank" rel="noopener noreferrer">{task.video_call_link}</a></td>
              <td>
                <div className={getStatusClass(task.start)}>
                  {getStatusText(task.start)}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="task-list-cards">
        {currentTasks.map((task) => (
          <div key={task.id} className="task-card">
            <div className="task-card-header">
              <span className="task-title">{task.title}</span>
              <span className="task-status">
                <div className={getStatusClass(task.start)}>
                  {getStatusText(task.start)}
                </div>
              </span>
            </div>
            <div className="task-card-body">
              <div>{t('dateColumn')}: {formatDate(task.start)}</div>
              <div>{t('timeColumn')}: {`${formatTime(task.start)}-${task.end ? formatTime(task.end) : 'N/A'}`}</div>
              <div>{t('hoursColumn')}: {calculateDuration(task.start, task.end)}</div>
              <div><a href={task.video_call_link} target="_blank" rel="noopener noreferrer">{t('videoRoomColumn')}</a></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CalendarTaskList;
